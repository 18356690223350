@import "~bootstrap/scss/bootstrap";
@import "~rfs/scss";
@import "../hamburgers/hamburgers";

@import "./uno-variable";
@import "./uno-mixin";
@import "./uno-custom";

#wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

#header {}

#content {
  position: relative;
  flex: 1;
}

#footer {
  .footer {
    &-copyright {
      letter-spacing: 1px;
      background: rgb(24,112,202);
      background: -moz-linear-gradient(0deg, rgba(24,112,202,1) 0%, rgba(64,199,233,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(24,112,202,1) 0%, rgba(64,199,233,1) 100%);
      background: linear-gradient(0deg, rgba(24,112,202,1) 0%, rgba(64,199,233,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1870ca",endColorstr="#40c7e9",GradientType=1);
    }
  }
}