@function rem($pixel-size, $base-size: $baseSize) {
  @if (unitless($pixel-size)) {
    $pixel-size: $pixel-size * 1px;
  }

  @return $pixel-size / $base-size * 1rem;
}

@function colors($key) {
  @return nth($list: $uno-colors, $n: $key);
}

@mixin uno-font-faces(
  $font-label,
  $font-name,
  $file-path,
  $font-weight: normal,
  $font-style: normal
) {
  @font-face {
    font-family: $font-name;
    src: url("#{$font-name}.eot");
    src: local("#{$font-name}"),
      url("#{$file-path}#{$font-name}.eot?#iefix") format("embedded-opentype"),
      url("#{$file-path}#{$font-name}.woff2") format("woff2"),
      url("#{$file-path}#{$font-name}.woff") format("woff"),
      url("#{$file-path}#{$font-name}.ttf") format("truetype"),
      url("#{$file-path}#{$font-name}.svg") format("svg");
    font-weight: $font-weight;
    font-style: $font-style;
  }

  #{".font-"}#{$font-label} {
    font-family: $font-name;
  }
}

@mixin uno-font-colors($colors: $uno-colors) {
  $index: 1;

  #{".color"} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        color: $color;
      }

      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          color: $color;
        }
      }

      $index: $index + 1;
    }
  }
}

@mixin uno-font-sizes() {
  $uno-size-labels: (
    "xs",
    "sm",
    "base",
    "lg",
    "xl",
    "2xl",
    "3xl",
    "4xl",
    "5xl",
    "6xl"
  );
  $uno-size-rem: (
    0.75rem,
    0.875rem,
    1rem,
    1.125rem,
    1.25rem,
    1.5rem,
    1.875rem,
    2.25rem,
    3rem,
    4rem
  );
  $index: 1;

  #{".text-"} {
    @each $label in $uno-size-labels {
      &#{$label} {
        @include font-size(nth($uno-size-rem, $index));
      }

      $index: $index + 1;
    }
  }
}

@mixin uno-background-colors($colors: $uno-colors) {
  $index: 1;
  #{".bg"} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        background: $color;
      }

      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          background: $color;
        }
      }

      $index: $index + 1;
    }
  }
}

@mixin uno-border-colors($colors: $uno-colors) {
  $index: 1;
  #{".border"} {
    @each $color in $colors {
      &#{if($index < 10, -c0#{$index}, -c#{$index})} {
        border-color: $color;
      }

      &-hover#{if($index < 10, -c0#{$index}, -c#{$index})} {
        &:hover {
          border-color: $color;
        }
      }

      $index: $index + 1;
    }
  }
}

@mixin uno-z-index($min: 1, $max: 10) {
  #{".z-index"} {
    @for $i from $min through $max {
      &-#{$i} {
        position: relative;
        z-index: $i;
      }
    }
  }
}

@include uno-font-faces("regular", "ProximaNova-Regular", "../fonts/");
@include uno-font-faces("light", "ProximaNova-Light", "../fonts/", 300);
@include uno-font-faces("bold", "ProximaNova-Bold", "../fonts/", bold);
@include uno-font-faces("extrabold", "ProximaNova-ExtraBold", "../fonts/", 800);

@include uno-font-sizes();
@include uno-font-colors();

@include uno-z-index();

@include uno-background-colors();
@include uno-border-colors();
